.about-page {
  @include main;
  padding-top: 70px;
  @include media($width1600) {
    padding-top: 60px;
  }
  @include media($width1366) {
    padding-top: 50px;
  }
  @include media($width1180) {
    padding-top: 40px;
  }
  @include media($width1024) {
    padding-top: 30px;
  }
  &__main {
    @include main;
    @include padding;
    padding-bottom: 240px;
    z-index: 50;
    @include media($width1600) {
      padding-bottom: 190px;
    }
    @include media($width1366) {
      padding-bottom: 130px;
    }
    @include media($width1180) {
      padding-bottom: 110px;
    }
    @include media($width1024) {
      padding-bottom: 90px;
    }
    .main {
      &__copy {
        width: 53%;
        padding: 30px 100px 0 0;
        float: left;
        @include media($width1600) {
          width: 60%;
          padding: 0 100px 0 0;
        }
        @include media($width1366) {
          padding: 0 60px 0 0;
        }
        @include media($width1180) {
          padding: 0 30px 0 0;
        }
        @include media($width768) {
          width: 100%;
          text-align: center;
          padding: 0;
        }
        h1 {
          margin: 0 0 55px;
          @include media($width1366) {
            margin: 0 0 45px;
          }
          @include media($width1180) {
            margin: 0 0 40px;
          }
          @include media($width1024) {
            margin: 0 0 35px;
          }
          @include media($width768) {
            margin: 0 0 30px;
          }
          @include media($width560) {
            margin: 0 0 25px;
          }
        }
        p {
          font-size: 1.8rem;
          line-height: 3.4rem;
          color: $about-p;
          margin: 0 0 25px;
          @include media($width1600) {
            margin: 0 0 20px;
          }
          @include media($width1366) {
            font-size: 1.6rem;
            line-height: 3.2rem;
          }
          @include media($width1180) {
            line-height: 2.8rem;
          }
          @include media($width1024) {
            font-size: 1.5rem;
            line-height: 2.3rem;
            margin: 0 0 20px;
          }
          @include media($width768) {
            font-size: 1.4rem;
            line-height: 2.1rem;
          }
          @include media($width560) {
            margin: 0 0 15px;
          }
        }
        & > *:last-child {
          margin: 0;
        }
      }
      &__illustration {
        width: 47%;
        position: relative;
        float: right;
        @include media($width1600) {
          width: 40%;
        }
        @include media($width768) {
          width: 100%;
          margin: 0 0 40px;
        }
        @include media($width560) {
          margin: 0 0 30px;
        }
        img {
          width: 100%;
          @include media($width768) {
            max-width: 400px;
            margin: 0 auto;
          }
          @include media($width560) {
            max-width: 80%;
          }
        }
      }
    }
  }
  &__quote {
    @include main;
    background-color: $gray-booking-bg;
    text-align: center;
    padding: 0 0 120px;
    @include media($width1600) {
      padding: 0 0 90px;
    }
    @include media($width1366) {
      padding: 0 0 80px;
    }
    @include media($width1024) {
      padding: 0 0 65px;
    }
    @include media($width768) {
      padding: 0 0 55px;
    }
    @include media($width560) {
      padding: 0 0 50px;
    }
    &:after {
      @include translateX;
      background-image: url(../images/about--line.png);
      background-size: cover;
      width: 1920px;
      height: 2386px;
      pointer-events: none;
      display: block;
      position: absolute;
      top: -235px;
      @include media($width1800) {
    		top: -260px;
    	}
      @include media($width1700) {
        width: 1800px;
        height: 2237px;
    	}
      @include media($width1600) {
        display: none;
      }
    }
    .quote {
      &__photo {
        width: 422px;
        margin: -140px auto 0;
        display: block;
        position: relative;
        @include media($width1600) {
          width: 380px;
        }
        @include media($width1366) {
          width: 340px;
          margin: -120px auto 0;
        }
        @include media($width1180) {
          width: 300px;
          margin: -100px auto 0;
        }
        @include media($width1024) {
          width: 280px;
          margin: -80px auto 0;
        }
        @include media($width768) {
          width: 250px;
          margin: -70px auto 0;
        }
        @include media($width560) {
          width: 210px;
          margin: -60px auto 0;
        }
        &:before {
          @include gradientBlue;
          border-radius: 50%;
          width: 208px;
          height: 208px;
          opacity: 0.55;
          display: block;
          filter: blur(31px);
          position: absolute;
          z-index: 5;
          top: 10px;
          left: 0;
          @include media($width1600) {
            width: 170px;
            height: 170px;
          }
          @include media($width1366) {
            width: 140px;
            height: 140px;
            top: 30px;
            left: 20px;
          }
          @include media($width768) {
            width: 100px;
            height: 100px;
            top: 20px;
            left: 10px;
          }
        }
        &:after {
          @include gradientYellow;
          border-radius: 50%;
          width: 98px;
          height: 98px;
          opacity: 0.55;
          display: block;
          filter: blur(31px);
          position: absolute;
          z-index: 5;
          bottom: 110px;
          right: 20px;
          @include media($width1600) {
            width: 80px;
            height: 80px;
          }
          @include media($width1366) {
            width: 60px;
            height: 60px;
            bottom: 70px;
            right: 0;
          }
          @include media($width768) {
            width: 50px;
            height: 50px;
            bottom: 50px;
            right: 40px;
          }
        }
        img {
          width: 100%;
          position: relative;
          z-index: 10;
        }
      }
      &__text {
        max-width: 1300px;
        font-size: 3rem;
        font-style: italic;
        line-height: 5rem;
        margin: -35px auto 65px;
        padding: 0 30px;
        @include media($width1600) {
          font-size: 2.6rem;
          line-height: 4.4rem;
        }
        @include media($width1366) {
          max-width: 1100px;
          font-size: 2.4rem;
          line-height: 4.2rem;
        }
        @include media($width1180) {
          max-width: 900px;
          font-size: 2.2rem;
          line-height: 3.4rem;
        }
        @include media($width1024) {
          max-width: 800px;
          font-size: 2rem;
          line-height: 3rem;
          margin: -20px auto 50px;
        }
        @include media($width768) {
          max-width: 100%;
          font-size: 1.8rem;
          line-height: 2.6rem;
          margin: -20px auto 40px;
        }
        @include media($width560) {
          font-size: 1.6rem;
          line-height: 2.4rem;
          margin: -20px auto 35px;
        }
      }
      &__signature {
        font-size: 3.4rem;
        font-style: italic;
        color: $signature;
        display: inline-block;
        @include media($width1600) {
          font-size: 3rem;
        }
        @include media($width1366) {
          font-size: 2.6rem;
        }
        @include media($width1180) {
          font-size: 2.2rem;
          font-weight: 700;
        }
        @include media($width1024) {
          font-size: 2rem;
        }
        @include media($width768) {
          font-size: 1.8rem;
        }
        @include media($width560) {
          font-size: 1.6rem;
        }
      }
    }
  }
  &__process {
    @include main;
    padding-top: 160px;
    padding-bottom: 10px;
    z-index: 100;
    @include media($width1600) {
      padding-top: 130px;
    }
    @include media($width1366) {
      padding-top: 110px;
    }
    @include media($width1180) {
      padding-top: 95px;
    }
    @include media($width1024) {
      padding-top: 70px;
    }
    @include media($width768) {
      padding-top: 55px;
    }
    h3 {
      text-align: center;
      margin: 0 0 120px;
      @include media($width1600) {
        margin: 0 0 110px;
      }
      @include media($width1366) {
        margin: 0 0 95px;
      }
      @include media($width1180) {
        margin: 0 0 80px;
      }
      @include media($width1024) {
        margin: 0 0 70px;
      }
      @include media($width768) {
        margin: 0 0 55px;
      }
    }
    .process {
      &__item {
        @include main;
        @include media($width1024) {
          margin: 0 0 50px !important;
          padding: 0 30px!important;
        }
        @include media($width1024) {
          margin: 0 0 35px !important;
        }
        .graphic {
          float: left;
          @include media($width1024) {
            width: 100% !important;
            margin: 0 !important;
          }
          img {
            width: 100%;
            @include media($width1024) {
              width: 35% !important;
              margin: 0 auto 40px !important;
            }
          }
        }
        .copy {
          float: left;
          @include media($width1024) {
            width: 100% !important;
            text-align: center !important;
            margin: 0 !important;
          }
          .inner {
            @include main;
            @include media($width1024) {
              max-width: 600px !important;
              margin: 0 auto !important;
              top: auto !important;
              float: none !important;
            }
            @include media($width768) {
              max-width: 500px !important;
            }
            @include media($width560) {
              max-width: 350px !important;
            }
            h4 {
              margin: 0 0 20px;
            }
            p {
              font-size: 2rem;
              line-height: 4rem;
              color: $about-p;
              @include media($width1366) {
                font-size: 1.8rem;
          			line-height: 3.6rem;
          		}
              @include media($width1180) {
          			line-height: 3rem;
          		}
              @include media($width1024) {
                font-size: 1.6rem;
          			line-height: 2.6rem;
          		}
            }
          }
        }
        &--first {
          margin: 0 0 170px;
          padding: 0 22% 0 10%;
          @include media($width1600) {
            margin: 0 0 50px;
            padding: 0 20% 0 10%;
          }
          @include media($width1366) {
            padding: 0 12% 0 6%;
          }
          @include media($width1180) {
            padding: 0 10% 0 5%;
          }
          .graphic {
            width: 35%;
            margin: -100px 13% 0 0;
            @include media($width1800) {
              margin: -75px 13% 0 0;
            }
            @include media($width1600) {
              width: 37%;
              margin: -60px 8% 0 0;
            }
            @include media($width1180) {
              width: 38%;
              margin: -60px 5% 0 0;
            }
          }
          .copy {
            width: 52%;
            @include media($width1600) {
              width: 55%;
            }
            @include media($width1180) {
              width: 57%;
            }
          }
        }
        &--second {
          margin: 0 0 45px;
          padding: 0 10% 0 21%;
          @include media($width1600) {
            padding: 0 10% 0 20%;
          }
          @include media($width1366) {
            padding: 0 6% 0 12%;
          }
          @include media($width1180) {
            padding: 0 5% 0 10%;
          }
          .graphic {
            width: 34%;
            margin: 0 0 0 14%;
            float: right;
            @include media($width1600) {
              width: 37%;
              margin: 0 0 0 8%;
            }
            @include media($width1180) {
              width: 38%;
              margin: 0 0 0 5%;
            }
          }
          .copy {
            width: 52%;
            margin: 150px 0 0;
            @include media($width1600) {
              width: 55%;
            }
            @include media($width1180) {
              width: 57%;
            }
            .inner {
              top: calc(50% - 60px);
              @include media($width1600) {
                top: calc(50% - 80px);
              }
            }
          }
        }
        &--third {
          padding: 0 21% 0 8%;
          @include media($width1600) {
            padding: 0 20% 0 10%;
          }
          @include media($width1366) {
            padding: 0 12% 0 6%;
          }
          @include media($width1180) {
            padding: 0 10% 0 5%;
          }
          .graphic {
            width: 34%;
            margin: 0 17% 0 0;
            @include media($width1800) {
              margin: 0 12% 0 0;
            }
            @include media($width1600) {
              width: 37%;
              margin: 0 8% 0 0;
            }
            @include media($width1180) {
              width: 38%;
              margin: 0 5% 0 0;
            }
          }
          .copy {
            width: 49%;
            margin: 200px 0 0;
            @include media($width1800) {
              width: 54%;
            }
            @include media($width1700) {
              margin: 180px 0 0;
            }
            @include media($width1600) {
              width: 55%;
              margin: 140px 0 0;
            }
            @include media($width1180) {
              width: 57%;
            }
            .inner {
              top: calc(50% + 60px);
              @include media($width1600) {
                top: auto;
              }
            }
          }
        }
      }
    }
  }
  &__button {
    @include main;
    text-align: center;
    padding-bottom: 250px;
    @include media($width1800) {
  		padding-bottom: 220px;
  	}
    @include media($width1700) {
  		padding-bottom: 180px;
  	}
    @include media($width1600) {
      padding-top: 120px;
      padding-bottom: 160px;
    }
    @include media($width1366) {
      padding-top: 100px;
      padding-bottom: 140px;
    }
    @include media($width1180) {
      padding-top: 80px;
      padding-bottom: 1150px;
    }
    @include media($width1024) {
      padding-top: 20px;
      padding-bottom: 90px;
    }
    @include media($width768) {
      padding-top: 10px;
      padding-bottom: 70px;
    }
  }
}
