.contact-page {
  @include main;
  @include padding;
  padding-top: 70px;
  padding-bottom: 80px;
  @include media($width1600) {
    padding-top: 60px;
  }
  @include media($width1366) {
    padding-top: 50px;
    padding-bottom: 70px;
  }
  @include media($width1180) {
    padding-top: 40px;
    padding-bottom: 60px;
  }
  @include media($width1024) {
    padding-top: 30px;
    padding-bottom: 50px;
  }
  @include media($width768) {
    padding-bottom: 50px;
  }
  @include media($width560) {
    padding-top: 20px;
    padding-bottom: 40px;
  }
  &__copy {
    width: 30%;
    float: left;
    @include media($width1366) {
      width: 35%;
    }
    @include media($width1180) {
      width: 40%;
    }
    @include media($width1024) {
      width: 100%;
      margin: 0 0 10px;
    }
    h1 {
      margin: 0 0 40px;
      @include media($width1024) {
        display: none;
      }
    }
    .item {
      @include main;
      font-size: 2.6rem;
      font-weight: 700;
      line-height: 4rem;
      margin: 0 0 50px;
      @include media($width1366) {
        font-size: 2.5rem;
        line-height: 4rem;
        margin: 0 0 40px;
      }
      @include media($width1024) {
        font-size: 2.2rem;
        line-height: 3.5rem;
        text-align: center;
        margin: 0 0 40px;
      }
      @include media($width768) {
        font-size: 2rem;
        line-height: 3.2rem;
        margin: 0 0 35px;
      }
      &:after {
        background-color: $yellow;
        width: 70px;
        height: 2px;
        display: block;
        position: absolute;
        top: calc(100% + 10px);
        left: 0;
        @include media($width1024) {
          @include translateX;
          width: 60px;
          position: absolute;
        }
      }
      a {
        &:hover {
          color: $about-p;
        }
      }
    }
    .graphic {
      @include main;
      margin: 10px 0 0;
      @include media($width1024) {
        display: none;
      }
      img {
        width: calc(100% + 53px);
        margin: 0 0 0 -53px;
        @include media($width1600) {
          width: 100%;
          margin: 0;
        }
        @include media($width1366) {
          width: 85%;
        }
      }
      &--mobile {
        display: none;
        @include media($width1024) {
          display: block;
        }
        img {
          max-width: 400px;
          margin: 0 auto 40px;
          @include media($width768) {
            max-width: 300px;
          }
          @include media($width560) {
            max-width: 280px;
            margin: 0 auto 30px;
          }
        }
      }
    }
  }
  &__form {
    width: 50%;
    padding: 5px 0 0;
    position: relative;
    float: right;
    @include media($width1366) {
      width: 55%;
    }
    @include media($width1024) {
      width: 100%;
    }
    h4 {
      margin: 0 0 50px;
      @include media($width1366) {
        margin: 0 0 40px;
      }
      @include media($width1024) {
        font-weight: 900;
        text-align: center;
      }
      @include media($width768) {
        margin: 0 0 30px;
      }
    }
    .email {
      width: calc(63% - 10px);
      float: left;
      @include media($width768) {
        width: calc(50% - 10px);
      }
      @include media($width560) {
        width: 100%;
      }
      input {
        width: 100%;
      }
    }
    .phone {
      width: calc(37% - 10px);
      float: right;
      @include media($width768) {
        width: calc(50% - 10px);
      }
      @include media($width560) {
        width: 100%;
      }
      input {
        width: 100%;
      }
    }
    .submit {
      @include main;
      text-align: center;
    }
    input[type="submit"] {
      margin: 0 auto;
      display: block;
    }
  }
}

.checkbox {
  @include main;
  margin: 25px 0 100px;
  input {
    display: none;
    &:checked {
      & + label {
        .circle {
          &:before {
            background-color: $green;
          }
        }
      }
    }
  }
  label {
    @include main;
    cursor: pointer;
    span {
      &.circle {
        @include transition;
        background-color: $gray-btn-bg;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        margin: 2px 0 0;
        position: relative;
        float: left;
        &:before {
          @include translate;
          @include transition;
          border-radius: 50%;
          background-color: darken($gray-btn-bg,10%);
          width: 14px;
          height: 14px;
          opacity: 1;
          display: block;
          position: absolute;
        }
      }
      &.text {
        width: calc(100% - 55px);
        font-size: 2.2rem;
        font-weight: 500;
        line-height: 3.2rem;
        text-decoration: underline;
        color: $about-p;
        float: right;
      }
    }
  }
}

.wpcf7-form {
	br {
		display: none;
	}
}

.rule-checkbox {
  @include main;
  margin: 25px 0 100px;
  @include media($width1366) {
    margin: 25px 0 80px;
  }
  @include media($width1024) {
    margin: 25px 0 60px;
  }
  @include media($width768) {
    margin: 15px 0 40px;
  }
  .wpcf7-list-item {
    @include main;
    margin: 0;
    padding: 0;
    input[type="checkbox"] {
      opacity: 0;
      &:checked {
				& + .wpcf7-list-item-label {
					&:after {
            opacity: 1;
          }
				}
			}
    }
    .wpcf7-list-item-label {
      width: calc(100% - 60px);
      font-size: 2rem;
      font-weight: 500;
      line-height: 3rem;
      text-decoration: underline;
      color: $about-p;
      cursor: pointer;
      position: relative;
      float: right;
      @include media($width1366) {
        width: calc(100% - 50px);
        font-size: 1.8rem;
        line-height: 2.8rem;
      }
      @include media($width1024) {
        font-size: 1.6rem;
        line-height: 2.4rem;
      }
      @include media($width768) {
        font-size: 1.4rem;
        line-height: 2.2rem;
      }
      @include media($width560) {
        width: calc(100% - 34px);
      }
      &:before {
        background-color: darken($gray-booking-bg,5%);
        border-radius: 50%;
        width: 32px;
        height: 32px;
        display: block;
        position: absolute;
        z-index: 10;
        top: 2px;
        left: -60px;
        @include media($width1366) {
          width: 29px;
          height: 29px;
          left: -50px;
        }
        @include media($width1024) {
          top: 0;
        }
        @include media($width560) {
          width: 23px;
          height: 23px;
          top: 2px;
          left: -34px;
        }
      }
      &:after {
        @include transition;
        background-color: $green;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        opacity: 0;
        display: block;
        position: absolute;
        z-index: 20;
        top: 11px;
        left: -52px;
        @include media($width1366) {
          width: 15px;
          height: 15px;
          top: 10px;
          left: -43px;
        }
        @include media($width1024) {
          top: 7px;
        }
        @include media($width560) {
          width: 11px;
          height: 11px;
          top: 8px;
          left: -28px;
        }
      }
    }
  }
}
