.practice-page {
  @include main;
  @include padding;
  padding-top: 90px;
  padding-bottom: 180px;
  @include media($width1600) {
		padding-top: 80px;
    padding-bottom: 160px;
	}
  @include media($width1366) {
		padding-top: 60px;
    padding-bottom: 120px;
	}
  @include media($width1180) {
		padding-top: 50px;
    padding-bottom: 100px;
	}
  @include media($width1024) {
		padding-top: 40px;
    padding-bottom: 80px;
	}
  @include media($width768) {
		padding-top: 20px;
    padding-bottom: 30px;
	}
  @include media($width560) {
		padding-bottom: 20px;
	}
  h1 {
    margin: 0 0 50px;
    @include media($width1024) {
  		margin: 0 0 40px;
  	}
    @include media($width768) {
  		text-align: center;
  	}
  }
  &__wrapper {
    @include main;
    @include transition;
  }
  &__content {
    @include main;
    margin: 0 0 80px;
    @include media($width1366) {
      margin: 0 0 70px;
    }
    @include media($width1024) {
      margin: 0 0 60px;
    }
    @include media($width768) {
      margin: 0;
    }
    @include media($width560) {
      margin: 0 0 10px;
    }
    .content {
      &__item {
        @include main;
        display: none;
        @include media($width768) {
          margin: 0 0 50px;
      		display: block;
      	}
        @include media($width560) {
          margin: 0 0 40px;
      	}
        .item {
          &__copy {
            width: 62%;
            padding: 0 100px 0 0;
            float: left;
            @include media($width1600) {
          		width: 66%;
              padding: 0 90px 0 0;
          	}
            @include media($width1366) {
          		padding: 0 80px 0 0;
          	}
            @include media($width1180) {
          		width: 65%;
              padding: 0 60px 0 0;
          	}
            @include media($width1024) {
          		padding: 0 40px 0 0;
          	}
            @include media($width768) {
              width: 100%;
              text-align: center;
          		padding: 0;
          	}
            h4 {
              margin: 0 0 25px;
            }
            p {
              max-width: 830px;
              font-size: 1.8rem;
              line-height: 3.4rem;
              color: $about-p;
              @include media($width1366) {
          			font-size: 1.6rem;
          			line-height: 3.2rem;
          		}
              @include media($width1180) {
          			line-height: 3rem;
          		}
              @include media($width1024) {
                font-size: 1.4rem;
          			line-height: 2.6rem;
          		}
            }
          }
          &__graphic {
            width: 30%;
            margin: 0 8% 0 0;
            float: right;
            @include media($width1600) {
          		width: 34%;
              margin: 0;
          	}
            @include media($width1180) {
          		width: 35%;
          	}
            @include media($width768) {
              width: 100%;
          		margin: 0 0 40px;
          	}
            img {
              max-width: 100%;
              margin: -130px 0 0;
              @include media($width1600) {
            		margin: -120px 0 0;
            	}
              @include media($width1366) {
            		margin: -110px 0 0;
            	}
              @include media($width1180) {
            		margin: -90px 0 0;
            	}
              @include media($width1024) {
            		margin: -80px 0 0;
            	}
              @include media($width768) {
                max-width: 400px;
            		margin: 0 auto;
            	}
              @include media($width560) {
                max-width: 75%;
            	}
            }
          }
        }
        &.active {
          display: block;
        }
      }
    }
  }
  &__tabs {
    @include media($width768) {
      display: none;
    }
    li {
      @include transition;
      border-radius: 15px;
      background-color: $gray-btn-bg;
      width: calc(25% - 13.5px);
      height: 250px;
      text-align: center;
      color: darken($signature,5%);
      cursor: pointer;
      overflow: hidden;
      @include media($width1600) {
        height: 220px;
      }
      @include media($width1366) {
        height: 190px;
      }
      @include media($width1180) {
        height: 160px;
      }
      @include media($width1024) {
        height: 140px;
        padding: 0 15px;
      }
      &:after {
        @include transition;
        @include gradientPractice;
        width: 100%;
        height: 100%;
        opacity: 0;
        display: block;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
      }
      & + li {
        margin: 0 0 0 18px;
      }
      span {
        @include translateY;
        max-width: 190px;
        font-size: 2.6rem;
        font-weight: 700;
        line-height: 3.4rem;
        display: inline-block;
        top: calc(50% - 2px);
        @include media($width1600) {
          font-size: 2.4rem;
          line-height: 3.2rem;
      	}
        @include media($width1366) {
          font-size: 2.2rem;
          line-height: 2.8rem;
      	}
        @include media($width1180) {
          font-size: 2rem;
          line-height: 2.6rem;
      	}
        @include media($width1024) {
          font-size: 1.8rem;
          line-height: 2.2rem;
      	}
      }
      &:hover {
        background-color: lighten($gray-btn-bg,2%);
        color: lighten($signature,5%);
      }
      &.active {
        background-color: transparent;
        color: $white;
        box-shadow: 0px 10px 30px 0px rgba(79,137,235,0.5);
        @include media($width1366) {
      		box-shadow: 0px 8px 20px 0px rgba(79,137,235,0.5);
      	}
        &:after {
          opacity: 1;
        }
      }
    }
  }
}
