.homepage {
  @include main;
  @include padding;
  margin: 0 0 100px;
  padding-top: 60px;
  padding-bottom: 60px;
  @include media($width1600) {
    margin: 0 0 80px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @include media($width1366) {
    margin: 0 0 70px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @include media($width1180) {
    margin: 0 0 60px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @include media($width768) {
    margin: 0 0 40px;
  }
  @include media($width560) {
    margin: 0 0 30px;
  }
  &__copy {
    width: 57%;
    padding: 50px 50px 0 0;
    float: left;
    @include media($width1600) {
      width: 60%;
      padding: 40px 50px 0 0;
    }
    @include media($width1366) {
      width: 58%;
    }
    @include media($width1180) {
      padding: 30px 40px 0 0;
    }
    @include media($width1024) {
      width: 61%;
      padding: 20px 30px 0 0;
    }
    @include media($width768) {
      width: 100%;
      text-align: center;
      padding: 35px 0 0;
    }
    @include media($width560) {
      padding: 30px 0 0;
    }
    .inner {
      @include main;
      h1 {
        margin: 0 0 41px;
        @include media($width1366) {
      		margin: 0 0 35px;
      	}
        @include media($width1180) {
      		margin: 0 0 30px;
      	}
        @include media($width1024) {
      		margin: 0 0 25px;
      	}
        @include media($width768) {
      		margin: 0 0 20px;
      	}
        @include media($width560) {
      		margin: 0 0 15px;
      	}
      }
      p {
        margin: 0 0 60px;
        @include media($width1600) {
          margin: 0 0 50px;
        }
        @include media($width1366) {
          margin: 0 0 45px;
        }
        @include media($width1180) {
          margin: 0 0 40px;
        }
        @include media($width1024) {
          margin: 0 0 35px;
        }
        @include media($width560) {
          margin: 0 0 30px;
        }
      }
      .btn-group {
        @include main;
        .btn {
          float: left;
          @include media($width768) {
            vertical-align: top;
            float: none;
          }
          @include media($width560) {
            width: 100%;
            float: left;
          }
          & + .btn {
            margin: 0 0 0 16px;
            @include media($width560) {
              margin: 20px 0 0;
            }
          }
        }
      }
    }
  }
  &__illustration {
    width: 41.5%;
    margin: 0 1.5% 0 0;
    float: right;
    @include media($width1600) {
      width: 40%;
      margin: 0;
    }
    @include media($width1366) {
      width: 42%;
    }
    @include media($width1024) {
      width: 39%;
    }
    @include media($width768) {
      width: 100%;
    }
    img {
      max-width: 100%;
      @include media($width768) {
        max-width: 350px;
        margin: 0 auto;
      }
      @include media($width560) {
        max-width: 75%;
      }
    }
  }
}
