.booking {
  @include main;
  background-color: $gray-booking-bg;
  text-align: center;
  padding: 70px 0 75px;
  @include media($width1600) {
    padding: 60px 0 65px;
  }
  @include media($width1366) {
    padding: 50px 0 55px;
  }
  @include media($width1180) {
    padding: 42px 0 45px;
  }
  @include media($width1024) {
    padding: 35px 0 40px;
  }
  @include media($width768) {
    padding: 45px 0 50px;
  }
  &:before,
  &:after {
    background-size: cover;
    pointer-events: none;
    display: block;
    position: absolute;
    z-index: 50;
    bottom: 0;
  }
  &:before {
    background-image: url(../images/booking--before.png);
    width: 300px;
    height: 350px;
    left: 0;
    @include media($width1600) {
      width: 257px;
      height: 300px;
    }
    @include media($width1366) {
      width: 223px;
      height: 260px;
    }
    @include media($width1180) {
      width: 189px;
      height: 220px;
    }
    @include media($width1024) {
      width: 154px;
      height: 180px;
    }
    @include media($width768) {
      width: 130px;
      height: 150px;
    }
  }
  &:after {
    background-image: url(../images/booking--after.png);
    width: 330px;
    height: 350px;
    right: 0;
    @include media($width1600) {
      width: 283px;
      height: 300px;
    }
    @include media($width1366) {
      width: 246px;
      height: 260px;
    }
    @include media($width1180) {
      width: 208px;
      height: 220px;
    }
    @include media($width1024) {
      width: 170px;
      height: 180px;
    }
    @include media($width768) {
      width: 140px;
      height: 140px;
    }
  }
  h3 {
    margin: 0 0 35px;
    z-index: 100;
    @include media($width1366) {
      margin: 0 0 30px;
    }
    @include media($width1024) {
      margin: 0 0 25px;
    }
  }
  .btn {
    position: relative;
    z-index: 100;
  }
}
