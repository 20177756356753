.header {
  @include padding;
  @include transition;
  background-color: $white;
  width: 100%;
  height: 130px;
  position: fixed;
  z-index: 300;
  top: 0;
  left: 0;
  @include media($width1600) {
    height: 120px;
  }
  @include media($width1366) {
    height: 110px;
  }
  @include media($width1180) {
    height: 100px;
  }
  @include media($width1024) {
    height: 90px;
  }
  @include media($width768) {
    height: 80px;
  }
  &__logo {
    @include translateY;
    z-index: 400;
    float: left;
    img {
      height: 46px;
      @include media($width768) {
        height: 43px;
      }
      @include media($width560) {
        height: 40px;
      }
    }
  }
  &__trigger {
    @include translateY;
    font-size: 1.4rem;
    font-weight: 900;
    text-transform: uppercase;
    color: $signature;
    cursor: pointer;
    padding: 0 28px 0 0;
    display: none;
    z-index: 400;
    float: right;
    @include media($width768) {
      display: block;
    }
    &:before,
    &:after {
      @include translateY;
      @include transition;
      background-image: url(../images/menu--icon.png);
      background-size: cover;
      width: 15px;
      height: 15px;
      pointer-events: none;
      display: block;
      position: absolute;
      top: calc(50% + 1px);
      right: 0;
    }
    &:before {
      background-image: url(../images/menu--close.png);
      opacity: 0;
    }
    &.menu-visible {
      @include translateReset;
      position: fixed;
      top: 42px;
      right: 25px;
      @include media($width560) {
        top: 37px;
      }
      &:before {
        opacity: 1;
        top: 50%;
      }
      &:after {
        opacity: 0;
      }
    }
  }
  &__menu {
    @include translateY;
    top: calc(50% - 1px);
    float: right;
    @include media($width768) {
      @include translateReset;
      @include transition;
      background-color: $white;
      width: 100%;
      height: 100vh;
      opacity: 0;
      pointer-events: none;
      position: fixed;
      top: 0;
      left: 100%;
    }
    ul {
      width: auto;
      @include media($width768) {
        @include translateY;
        width: 100%;
      }
      li {
        width: auto;
        @include media($width768) {
          width: 100%;
          text-align: center;
        }
        & + li {
          margin: 0 0 0 65px;
          @include media($width1366) {
            margin: 0 0 0 60px;
        	}
          @include media($width1180) {
            margin: 0 0 0 50px;
        	}
          @include media($width1024) {
            margin: 0 0 0 40px;
        	}
          @include media($width768) {
            margin: 15px 0 0;
        	}
        }
        a {
          font-size: 2.2rem;
          font-weight: 400;
          line-height: 4.4rem;
          vertical-align: middle;
          display: inline-block;
          position: relative;
          @include media($width1600) {
            font-size: 2rem;
        	}
          @include media($width1366) {
            font-size: 1.9rem;
            line-height: 4.2rem;
        	}
          @include media($width1180) {
            font-size: 1.8rem;
        	}
          @include media($width1024) {
            font-size: 1.7rem;
            line-height: 4rem;
        	}
          @include media($width768) {
            font-size: 1.6rem;
            line-height: 3.6rem;
        	}
          &:after {
            background-color: transparent;
            width: 100%;
            height: 2px;
            display: block;
            position: absolute;
            top: calc(100% + 1px);
            left: 0;
          }
        }
        &:last-child {
          margin: 0 0 0 55px;
          @include media($width1180) {
            margin: 0 0 0 50px;
          }
          @include media($width1024) {
            margin: 0 0 0 40px;
          }
          @include media($width768) {
            margin: 32px 0 0;
          }
          a {
            @include gradientBlue;
            border-radius: 48px;
            font-size: 1.5rem;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 0.05em;
            color: $white;
            padding: 0 70px;
            @include media($width1366) {
              padding: 0 60px;
          	}
            @include media($width1180) {
              padding: 0 50px;
          	}
            @include media($width1024) {
              padding: 0 40px;
          	}
            @include media($width768) {
              font-size: 1.4rem;
              font-weight: 400;
              line-height: 4.3rem;
              padding: 0 45px 1px;
          	}
            &:after {
              display: none;
            }
          }
          &.current-menu-item {
            a {
              @include gradientYellow;
            }
          }
        }
        &.current-menu-item {
          a {
            font-weight: 900;
            &:after {
              background-color: $yellow;
            }
          }
        }
      }
    }
    &.menu-visible {
      opacity: 1;
      pointer-events: auto;
      left: 0%;
    }
  }
  &.scrolling {
    height: 90px;
    box-shadow: 0px 5px 20px 0px rgba(0,0,0,0.08);
    @include media($width1600) {
      height: 80px;
    }
    .header__trigger {
      &.menu-visible {
        top: 37px;
        @include media($width560) {
          top: 37px;
        }
      }
    }
  }
}
