.news-page {
  @include main;
  @include padding;
  padding-top: 100px;
  padding-bottom: 165px;
  @include media($width1600) {
    padding-top: 80px;
    padding-bottom: 120px;
  }
  @include media($width1366) {
    padding-top: 60px;
    padding-bottom: 100px;
  }
  @include media($width1180) {
    padding-top: 40px;
    padding-bottom: 90px;
  }
  @include media($width1024) {
    padding-top: 30px;
    padding-bottom: 80px;
  }
  @include media($width768) {
    padding-top: 25px;
    padding-bottom: 70px;
  }
  @include media($width560) {
    padding-top: 20px;
    padding-bottom: 60px;
  }
  h1 {
    margin: 0 0 100px;
    @include media($width1600) {
      margin: 0 0 80px;
    }
    @include media($width1366) {
      margin: 0 0 70px;
    }
    @include media($width1180) {
      margin: 0 0 60px;
    }
    @include media($width1024) {
      margin: 0 0 50px;
    }
    @include media($width768) {
      margin: 0 0 40px;
    }
    @include media($width560) {
      text-align: center;
      margin: 0 0 35px;
    }
    &:after {
      @include translateY;
      background-color: $yellow;
      width: 100%;
      height: 2px;
      display: block;
      position: absolute;
      z-index: -1;
      top: calc(50% + 3px);
      left: 0;
      @include media($width560) {
        display: none;
      }
    }
    span {
      background-color: $white;
      padding: 0 115px 0 0;
      display: inline-block;
      @include media($width1600) {
        padding: 0 90px 0 0;
      }
      @include media($width1366) {
        padding: 0 70px 0 0;
      }
      @include media($width1024) {
        padding: 0 50px 0 0;
      }
      @include media($width768) {
        padding: 0 40px 0 0;
      }
      @include media($width560) {
        padding: 0;
      }
    }
  }
  &__list {
    li {
      & + li {
        margin: 55px 0 0;
        @include media($width1600) {
          margin: 50px 0 0;
        }
        @include media($width1366) {
          margin: 45px 0 0;
        }
        @include media($width1024) {
          margin: 40px 0 0;
        }
        @include media($width768) {
          margin: 30px 0 0;
        }
        @include media($width560) {
          margin: 25px 0 0;
        }
      }
      .image {
        border-radius: 20px 0 0 20px;
        background-size: cover;
        background-position: center center;
        width: 39%;
        overflow: hidden;
        position: relative;
        float: left;
        @include media($width1366) {
          width: 35%;
        }
        @include media($width1024) {
          width: 32%;
        }
        @include media($width768) {
          border: 1px solid rgba($lead,0.2);
          border-bottom: 0;
          border-radius: 20px 20px 0 0;
          width: 100%;
          height: 280px;
        }
        @include media($width560) {
          height: 230px;
        }
        .click {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          &:hover {
            background-color: rgba($white,0.3);
          }
        }
      }
      .copy {
        border: 1px solid rgba($lead,0.2);
        border-left: 0;
        border-radius: 0 20px 20px 0;
        width: 61%;
        padding: 45px 55px;
        float: right;
        @include media($width1600) {
          padding: 40px;
        }
        @include media($width1366) {
          width: 65%;
          padding: 30px 30px 35px;
        }
        @include media($width1180) {
          padding: 25px 30px 30px;
        }
        @include media($width1024) {
          width: 68%;
          padding: 25px 30px 30px;
        }
        @include media($width768) {
          border-left: 1px solid rgba($lead,0.2);
          border-top: 0;
          width: 100%;
          border-radius: 0 0 20px 20px;
          padding: 25px;
        }
        @include media($width560) {
          padding: 22px 20px 25px;
        }
        h4 {
          max-width: 600px;
          font-weight: 900;
          margin: 0 0 35px;
          @include media($width1600) {
            margin: 0 0 25px;
          }
          @include media($width1366) {
            max-width: 500px;
            margin: 0 0 15px;
          }
          a {
            &:hover {
              color: rgba($about-p,0.8);
            }
          }
        }
        p {
          font-size: 1.5rem;
          line-height: 2.5rem;
          color: rgba($about-p,0.6);
          margin: 0 0 45px;
          @include media($width1600) {
            margin: 0 0 30px;
          }
          @include media($width1366) {
            margin: 0 0 25px;
          }
          @include media($width1180) {
            line-height: 2.3rem;
            margin: 0 0 25px;
          }
          @include media($width1024) {
            margin: 0 0 20px;
          }
        }
        .info {
          @include main;
          .date {
            @include media($width1024) {
        			margin: 5px 0 0;
        		}
            @include media($width560) {
        			margin: 4px 0 0;
        		}
          }
          ul {
            &.tags {
              @include media($width1024) {
          			display: none;
          		}
            }
          }
          .btn {
            float: right;
          }
        }
      }
    }
    &--recently {
      li {
        border: 1px solid rgba($lead,0.3);
        border-radius: 20px;
        width: calc(33.3334% - 40px);
        overflow: hidden;
        @include media($width1600) {
          width: calc(33.3334% - 30px);
        }
        @include media($width1366) {
          width: calc(50% - 15px);
        }
        @include media($width768) {
          width: 100%;
        }
        &:nth-child(3) {
          @include media($width1366) {
            display: none;
          }
        }
        &:nth-child(2) {
          @include media($width768) {
            display: none;
          }
        }
        & + li {
          margin: 0 0 0 60px;
          @include media($width1600) {
            margin: 0 0 0 45px;
          }
          @include media($width1366) {
            margin: 0 0 0 30px;
          }
          @include media($width768) {
            margin: 0;
          }
        }
        .image {
          border-radius: 0;
          width: 100%;
          height: 250px;
          @include media($width1366) {
            height: 240px;
          }
          @include media($width1180) {
            height: 230px;
          }
          @include media($width560) {
            height: 220px;
          }
        }
        .copy {
          border: 0;
          border-radius: 0;
          width: 100%;
          padding: 30px 35px;
          @include media($width1600) {
            padding: 25px 30px;
          }
          @include media($width1366) {
            padding: 25px;
          }
          @include media($width1180) {
            padding: 20px 25px 25px;
          }
          @include media($width768) {
            padding: 20px 20px 25px;
          }
          h4 {
            margin: 0 0 20px;
            @include media($width1600) {
              margin: 0 0 15px;
            }
            @include media($width1180) {
              font-size: 2.2rem;
              line-height: 3rem;
              margin: 0 0 10px;
            }
            @include media($width1024) {
              font-size: 2rem;
              line-height: 2.8rem;
            }
            @include media($width768) {
              max-width: 100%;
            }
            a {
              color: rgba($about-p,0.8);
              &:hover {
                color: $lead;
              }
            }
          }
          p {
            margin: 0 0 30px;
            @include media($width1600) {
              margin: 0 0 25px;
            }
            @include media($width1366) {
              margin: 0 0 20px;
            }
            @include media($width1180) {
              margin: 0 0 15px;
            }
          }
          .info {
            margin: 0;
            .date {
              margin: 3px 0 0;
            }
          }
        }
      }
    }
  }
}

.post-page {
  @include main;
  @include padding;
  padding-top: 100px;
  padding-bottom: 250px;
  @include media($width1600) {
    padding-top: 80px;
    padding-bottom: 200px;
  }
  @include media($width1366) {
    padding-top: 60px;
    padding-bottom: 150px;
  }
  @include media($width1180) {
    padding-top: 40px;
    padding-bottom: 120px;
  }
  @include media($width1024) {
    padding-top: 30px;
    padding-bottom: 90px;
  }
  @include media($width768) {
    padding-bottom: 70px;
  }
  @include media($width560) {
    padding-bottom: 60px;
  }
  h1 {
    margin: 0 0 55px;
    @include media($width1600) {
      margin: 0 0 40px;
    }
    @include media($width1366) {
      margin: 0 0 30px;
    }
    @include media($width1024) {
      margin: 0 0 20px;
    }
    @include media($width560) {
      margin: 0 0 9px;
    }
  }
  .info {
    @include main;
    margin: 0 0 55px;
    @include media($width1600) {
      margin: 0 0 60px;
    }
    @include media($width1366) {
      margin: 0 0 45px;
    }
    @include media($width1180) {
      margin: 0 0 40px;
    }
    @include media($width1024) {
      margin: 0 0 35px;
    }
    @include media($width560) {
      margin: 0 0 23px;
    }
    ul {
      &.tags {
        @include media($width768) {
          display: none;
        }
      }
    }
  }
  .thumbnail {
    @include main;
    margin: 0 0 80px;
    @include media($width1600) {
      margin: 0 0 70px;
    }
    @include media($width1366) {
      margin: 0 0 60px;
    }
    @include media($width1180) {
      margin: 0 0 50px;
    }
    @include media($width768) {
      margin: 0 0 40px;
    }
    @include media($width560) {
      width: calc(100% + 60px);
      margin: 0 -30px 30px;
    }
    img {
      border-radius: 20px;
      width: 100%;
      @include media($width560) {
        border-radius: 0;
      }
    }
  }
  .c {
    @include main;
    margin: 0 0 200px;
    @include media($width1600) {
      margin: 0 0 180px;
    }
    @include media($width1366) {
      margin: 0 0 140px;
    }
    @include media($width1180) {
      margin: 0 0 100px;
    }
    @include media($width1024) {
      margin: 0 0 80px;
    }
    @include media($width768) {
      margin: 0 0 60px;
    }
    @include media($width560) {
      margin: 0 0 40px;
    }
    h2, h3, h4, h5 {
      @include main;
      font-weight: 700;
      margin: 15px 0 35px;
      @include media($width1366) {
        margin: 10px 0 25px;
      }
      @include media($width1024) {
        margin: 5px 0 20px;
      }
    }
    p {
      font-size: 2rem;
      line-height: 4rem;
      color: $about-p;
      margin: 0 0 35px;
      @include media($width1366) {
        font-size: 1.6rem;
        line-height: 3rem;
        margin: 0 0 25px;
      }
      @include media($width1180) {
        line-height: 2.8rem;
      }
      @include media($width1024) {
        font-size: 1.5rem;
        line-height: 2.4rem;
        margin: 0 0 20px;
      }
      @include media($width768) {
        font-size: 1.4rem;
        line-height: 2.2rem;
        margin: 0 0 15px;
      }
    }
    & > *:last-child {
      margin: 0;
    }
  }
  .recently {
    @include main;
    h3 {
      text-align: center;
      margin: 0 0 100px;
      @include media($width1600) {
        margin: 0 0 90px;
      }
      @include media($width1366) {
        margin: 0 0 80px;
      }
      @include media($width1180) {
        margin: 0 0 70px;
      }
      @include media($width1024) {
        margin: 0 0 60px;
      }
      @include media($width768) {
        margin: 0 0 50px;
      }
      @include media($width560) {
        margin: 0 0 40px;
      }
      &:after {
        @include translateY;
        background-color: $yellow;
        width: 100%;
        height: 2px;
        display: block;
        position: absolute;
        z-index: -1;
        top: calc(50% + 3px);
        left: 0;
        @include media($width768) {
          display: none;
        }
      }
      span {
        background-color: $white;
        padding: 0 130px;
        display: inline-block;
        @include media($width1600) {
          padding: 0 100px;
        }
        @include media($width1366) {
          padding: 0 80px;
        }
        @include media($width1180) {
          padding: 0 60px;
        }
        @include media($width1024) {
          padding: 0 40px;
        }
        @include media($width768) {
          padding: 0;
        }
      }
    }
  }
}

.date {
  font-size: 1.4rem;
  line-height: 3rem;
  margin: 5px 65px 0 0;
  padding: 0 0 0 30px;
  position: relative;
  float: left;
  @include media($width1366) {
    margin: 5px 40px 0 0;
  }
  @include media($width1180) {
    margin: 5px 30px 0 0;
  }
  @include media($width560) {
    font-size: 1.3rem;
    padding: 0 0 0 25px;
  }
  &:before {
    @include translateY;
    background-image: url(../images/icon--date.svg);
    background-size: cover;
    width: 16px;
    height: 16px;
    display: block;
    position: absolute;
    top: calc(50% + 1px);
    left: 0;
    @include media($width560) {
      width: 15px;
      height: 15px;
      top: 50%;
    }
  }
}

ul {
  &.tags {
    width: auto;
    margin: 5px 0 0;
    li {
      width: auto;
      & + li {
        margin: 0 0 0 12px;
      }
      a {
        border: 1px solid rgba($lead,0.1);
        height: 30px;
        font-size: 1rem;
        line-height: 2.6rem;
        pointer-events: none;
        padding: 0 14px;
        float: left;
        &:hover {
          border-color: rgba($lead,0.05);
          background-color: rgba($lead,0.05);
        }
      }
    }
  }
}
