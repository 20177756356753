@mixin main {
	width: 100%;
	position: relative;
	float: left;
}

@mixin transition {
	transition: 0.4s ease;
}

@mixin box-sizing {
	box-sizing: border-box;
}

@mixin translate {
	transform: translate(-50%,-50%);
	position: relative;
	top: 50%;
	left: 50%;
}

@mixin translateX {
	transform: translateX(-50%);
	position: relative;
	left: 50%;
}

@mixin translateY {
	transform: translateY(-50%);
	position: relative;
	top: 50%;
}

@mixin translateReset {
	-webkit-transform: translate(0);
	transform: translate(0);
	position: relative;
	top: auto;
	left: auto;
}

@mixin padding {
	padding: 0 200px;
	@include media($width1600) {
		padding: 0 150px;
	}
	@include media($width1366) {
		padding: 0 80px;
	}
	@include media($width1180) {
		padding: 0 40px;
	}
	@include media($width1024) {
		padding: 0 25px;
	}
}

@mixin gradientYellow {
	background: #f08c1d;
	background: -moz-linear-gradient(left,  #f08c1d 0%, #fed377 100%);
	background: -webkit-linear-gradient(left,  #f08c1d 0%,#fed377 100%);
	background: linear-gradient(to right,  #f08c1d 0%,#fed377 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f08c1d', endColorstr='#fed377',GradientType=1 );
}

@mixin gradientBlue {
	background: #1839ac;
	background: -moz-linear-gradient(left,  #1839ac 0%, #0464f7 100%);
	background: -webkit-linear-gradient(left,  #1839ac 0%,#0464f7 100%);
	background: linear-gradient(to right,  #1839ac 0%,#0464f7 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1839ac', endColorstr='#0464f7',GradientType=1 );
}

@mixin gradientPractice {
	background: #285fb1;
	background: -moz-linear-gradient(top,  #285fb1 0%, #1737a8 100%);
	background: -webkit-linear-gradient(top,  #285fb1 0%,#1737a8 100%);
	background: linear-gradient(to bottom,  #285fb1 0%,#1737a8 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#285fb1', endColorstr='#1737a8',GradientType=0 );
}

// ** Media queries mixin ** //

@mixin media($media) {
  @media only screen and #{$media} {
    @content;
  }
}
