* {
	@include box-sizing;
	&:before,
	&:after {
		@include box-sizing;
		content: '';
		display: none;
	}
}

::-moz-selection {
	background: $lead;
	color: $white;
}

::selection {
	background: $lead;
	color: $white;
}

html {
	font-size: 62.5%;
	line-height: 62.5%;
}

body {
	background-color: $white;
	font-family: 'Lato', sans-serif !important;
	font-size: 2.3rem;
	font-weight: 400;
	line-height: 4rem;
	color: $lead;
	margin: 0;
	padding: 0;
}

strong, b {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
}

a {
	@include transition;
	text-decoration: none;
	color: $lead;
	&:hover {
		color: $lead;
	}
}

p {
	font-size: 2rem;
	line-height: 3.4rem;
	margin: 0;
	@include media($width1600) {
		font-size: 1.8rem;
		line-height: 2.8rem;
	}
	@include media($width1366) {
		font-size: 1.7rem;
		line-height: 2.7rem;
	}
	@include media($width1180) {
		font-size: 1.6rem;
		line-height: 2.6rem;
	}
	@include media($width1024) {
		font-size: 1.5rem;
		line-height: 2.5rem;
	}
	@include media($width768) {
		font-size: 1.4rem;
		line-height: 2.4rem;
	}
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'Lato', sans-serif;
	font-weight: 900;
	margin: 0;
	position: relative;
}

h1 {
	font-size: 5.5rem;
	line-height: 6.5rem;
	@include media($width1600) {
		font-size: 5rem;
		line-height: 6rem;
	}
	@include media($width1366) {
		font-size: 4.5rem;
		line-height: 5.5rem;
	}
	@include media($width1180) {
		font-size: 4rem;
		line-height: 5rem;
	}
	@include media($width1024) {
		font-size: 3.5rem;
		line-height: 4.3rem;
	}
	@include media($width768) {
		font-size: 3rem;
		line-height: 3.6rem;
	}
	@include media($width560) {
		font-size: 2.6rem;
		font-weight: 600;
		line-height: 3.2rem;
	}
	&.page-title {
		font-size: 4.2rem;
		line-height: 5.2rem;
		@include media($width1366) {
			font-size: 3.8rem;
			line-height: 4.6rem;
		}
		@include media($width1180) {
			font-size: 3.2rem;
			line-height: 4rem;
		}
		@include media($width1024) {
			font-size: 2.8rem;
			line-height: 3.6rem;
		}
		@include media($width560) {
			font-size: 2.6rem;
			font-weight: 900;
			line-height: 3.4rem;
		}
	}
}

h2 {
	font-size: 4.5rem;
	line-height: 5.5rem;
	@include media($width1366) {
		font-size: 3.5rem;
		line-height: 4.5rem;
	}
}

h3 {
	font-size: 4rem;
	line-height: 5rem;
	@include media($width1600) {
		font-size: 3.6rem;
		line-height: 4.6rem;
  }
	@include media($width1366) {
		font-size: 3.2rem;
		line-height: 4.2rem;
  }
	@include media($width1180) {
		font-size: 3rem;
		line-height: 3.6rem;
  }
	@include media($width1024) {
		font-size: 2.8rem;
		line-height: 3.4rem;
  }
	@include media($width768) {
		font-size: 2.6rem;
		line-height: 3.2rem;
  }
}

h4 {
	font-size: 3rem;
	font-weight: 700;
	line-height: 4rem;
	@include media($width1366) {
		font-size: 2.4rem;
		line-height: 3rem;
	}
	@include media($width1024) {
		font-size: 2.2rem;
		line-height: 2.4rem;
	}
	&.news-title {
		font-size: 2.6rem;
		line-height: 3rem;
		@include media($width1366) {
			font-size: 2.3rem;
			line-height: 2.6rem;
		}
		@include media($width1024) {
			font-size: 2rem;
			line-height: 2.4rem;
		}
	}
}

h5 {
	font-size: 2.4rem;
	line-height: 3.2rem;
}

h6 {
	font-size: 1.2rem;
	font-weight: 400;
	line-height: 1.6rem;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea,
select {
	@include main;
	@include transition;
	border: 0;
	border-bottom: 2px solid rgba($lead,0.1);
	border-radius: 0;
	background-color: transparent;
	height: 57px;
	font-family: 'Lato', sans-serif;
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 100%;
	letter-spacing: 0.05em;
	color: $lead;
	margin: 0 0 10px;
	padding: 0 0 1px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	&:focus {
		border-color: rgba($lead,0.2);
		background-color: transparent;
	}
}

input[type="submit"] {
	border: 0;
	padding: 0 100px 1px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	@include media($width1024) {
		padding: 0 80px 1px;
	}
	@include media($width768) {
		padding: 0 60px 1px;
	}
	@include media($width560) {
		width: 100%;
	}
}

textarea {
	height: 57px;
	resize: none;
	padding: 20px 0 0;
}

::-webkit-input-placeholder {
	color: $lead !important;
	opacity: 0.5;
}

:-moz-placeholder {
	color: $lead !important;
	opacity: 0.5;
}

::-moz-placeholder {
	color: $lead !important;
	opacity: 0.5;
}

:-ms-input-placeholder {
	color: $lead !important;
	opacity: 0.5;
}

ul {
	@include main;
	li {
		@include main;
		position: relative;
	}
}

img {
	margin: 0;
	display: block;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* --- [ global styles ] --- */

.wrapper {
	@include main;
	overflow: hidden;
	padding: 130px 0 0;
	@include media($width1600) {
    padding: 120px 0 0;
  }
  @include media($width1366) {
    padding: 110px 0 0;
  }
  @include media($width1180) {
    padding: 100px 0 0;
  }
  @include media($width1024) {
    padding: 90px 0 0;
  }
  @include media($width768) {
    padding: 80px 0 0;
  }
}

.container {
	max-width: 1426px;
	margin: 0 auto;
	position: relative;
	&--light {
		max-width: 850px;
		@include media($width1366) {
      max-width: 750px;
    }
		@include media($width1180) {
      max-width: 700px;
    }
		@include media($width1024) {
      max-width: 650px;
    }
	}
}

button {
	@include transition;
	border: 0;
	background-color: transparent;
	cursor: pointer;
}

.btn {
	@include transition;
	border-radius: 48px;
	background-color: transparent;
	height: 44px;
	font-family: 'Lato', sans-serif;
	font-size: 1.5rem;
	font-weight: 900;
	line-height: 4.2rem;
	text-align: center;
	text-transform: uppercase;
	text-decoration: none;
	letter-spacing: 0.08em;
	cursor: pointer;
	padding: 0 55px;
	display: inline-block;
	@include media($width1600) {
		padding: 0 50px;
	}
	@include media($width1366) {
		font-size: 1.4rem;
		padding: 0 45px;
	}
	@include media($width1180) {
		padding: 0 35px;
	}
	@include media($width1024) {
		border-radius: 40px;
		height: 40px;
		font-size: 1.3rem;
		line-height: 3.9rem;
		padding: 0 30px;
	}
	&--news {
		border-radius: 40px;
		height: 40px;
		line-height: 3.8rem;
		padding: 0 80px;
		@include media($width1600) {
			padding: 0 70px;
		}
		@include media($width1366) {
			padding: 0 60px;
		}
		@include media($width1180) {
			padding: 0 50px;
		}
		@include media($width768) {
			padding: 0 40px;
		}
		@include media($width560) {
			height: 36px;
			line-height: 3.5rem;
			padding: 0 30px;
		}
	}
	&--recently {
		border-radius: 36px;
		height: 36px;
		line-height: 3.4rem;
		padding: 0 50px;
		@include media($width768) {
			padding: 0 40px;
		}
		@include media($width560) {
			padding: 0 30px;
		}
	}
	&--yellow {
		@include gradientYellow;
		color: $white;
		&:hover {
			color: $white;
			box-shadow: 0px 10px 25px -10px rgba(243,151,38,0.5);
		}
	}
	&--yellow-shadow {
		box-shadow: 0px 10px 25px -10px rgba(243,151,38,0.5);
		&:hover {
			box-shadow: 0px 10px 25px -10px rgba(243,151,38,0);
		}
	}
	&--gray {
		background-color: $gray-btn-bg;
		color: $gray-btn-text;
		&:hover {
			background-color: darken($gray-btn-bg,5%);
			color: darken($gray-btn-text,15%);
		}
	}
}
